import './style.css'

import * as AbsintheSocket from '@absinthe/socket';

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Socket as PhoenixSocket } from 'phoenix';
import React from 'react';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import fetch from 'isomorphic-fetch';
import { getAccessToken } from '@lib/persist';
import { getMainDefinition } from '@apollo/client/utilities';
import { navigate } from 'gatsby';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

const isBrowser = typeof window !== 'undefined';

const getAuthorizationValue = () => {
  const accessToken = getAccessToken()
  return accessToken ? `Bearer ${accessToken}` : '';
}

const httpLink = createHttpLink({
  uri: process.env.GATSBY_GRAPHQL_URI,
  fetch
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getAuthorizationValue(),
  },
}));

const phoenixSocket = isBrowser ?
  new PhoenixSocket(
    process.env.GATSBY_GRAPHQL_WEB_SOCKET_URI,
    {
      params: () => ({
        Authorization: getAuthorizationValue(),
      }),
    }
  )
  :
  undefined

const absintheSocket = isBrowser ? AbsintheSocket.create(phoenixSocket) : undefined;
const wsLink = isBrowser ? createAbsintheSocketLink(absintheSocket) : undefined;

const App = ({ children }) => {
  // Theme
  const theme = createTheme({
    palette: {
      mode: 'dark',
    }
  });

  // Auth
  const logoutLink = onError(({ graphQLErrors }) => {
    // TODO: Make this notify the user and redirect to login
    const isUnauthorized = graphQLErrors.some(
      (error) => error.code === 'Unauthorized'
    );
    if (isUnauthorized) {
      navigate(`/sessions/expired`);
    }
  });

  const splitLink = isBrowser
    ?
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      logoutLink.concat(authLink.concat(httpLink))
    )
    :
    undefined

  const apolloParams = {
    cache: new InMemoryCache(),
    ...(isBrowser ? { link: splitLink } : {})
  }

  const client = new ApolloClient(apolloParams);

  return (
    <Box sx={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, m: 0, p: 0 }}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ApolloProvider>
    </Box>
  );
};

export default App;
